<template>
  <div class="col-lg-12">
    <h6 class="heading-small">
      Review Inspection Checklist
    </h6>

    <!-- To Be Data Driven-->
    <div class="row">
      <div class="pl-3">
        <!-- iterate all the checklists in a base-checkbox element  -->
        <base-checkbox
          v-for="(checklist, index) in checklists"
          :key="checklist.label" 
          :checked="checklist.checked"
          v-model="checklists[index].checked"
          class="mb-2"
        >{{ checklist.label }}</base-checkbox>
      </div>
    </div>
      
    <div class="row">
      <div class="col-lg-12">
        <!-- base button only visible when all checkbox checked -->
        <base-button
          type="primary"
          class="my-4"
          :disabled="!allChecked"
          @click="allDone"
        >Next
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    venue: [Array,Object],
  },
  data() {
    return {
      checklists: [],
    };
  },
  mounted() {
    // mounted
    if (this.venue.inspection_checklists) {
      this.checklists = this.venue.inspection_checklists.map((checklist) => ({
        label: checklist,
        checked: false,
      }));
    }
    
    if (!this.venue.inspection_checklists || this.venue.inspection_checklists.length === 0) {
      this.$emit('complete', true);
    }
  },
  computed: {
    // computed
    allChecked() {
      return this.checklists.every((checklist) => checklist.checked);
    },
  },
  methods: {
    // methods
    allDone() {
      // emit event to let parent know all checkbox checked using complete event
      this.$emit('complete', true);
    },
  },
};
</script>