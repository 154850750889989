<template>
  <div class="container-fluid  align-items-center" v-loading="loading">
    <div class="row">
      <div class="col-lg-5">
        <base-input
          label="Select Event to merge"
        >
          <el-select v-model="duplicateLeadEvent" placeholder="Select Event to merge" >
            <el-option
              v-for="event in otherEvents"
              :key="event.id"
              :label="event.name"
              :value="event">
            </el-option>
          </el-select>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5">
        <merge-list :lead-event="duplicateLeadEvent" v-if="duplicateLeadEvent"></merge-list>
      </div>
      <div class="col-sm-2 text-center">
        <i class="fa-light fa-circle-arrow-right arrow-right"></i>
      </div>
      <div class="col-lg-5">
        <merge-list :lead-event="selectedLeadEvent"></merge-list>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 text-right mt-4">
        <button class="btn btn-primary" :disabled="! duplicateLeadEvent" @click="mergeEvents">Merge</button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Select,
  Option
} from "element-ui";
import MergeList from '@/views/Widgets/LeadDetails/MergeList';
import LeadEventService from '@/services/lead-event.service';
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    MergeList,
  },
  props: {
    selectedLeadEvent: {
      type: Object,
      required: true,
    },
    leadId: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      duplicateLeadEvent: null,
    };
  },
  computed: { 
    otherEvents() {
      let leadEvents = this.$store.getters["leads/getLeadEventsByLeadId"](this.leadId);
      if (! leadEvents.length) {
        return [];
      }

      return leadEvents.filter((item) => item.id !== this.selectedLeadEvent.id);
    },
  },
  methods: {
    async mergeEvents() {
      if (!this.duplicateLeadEvent) {
        return;
      }
      this.loading = true;
      let self = this;
      await LeadEventService.mergeEvents(this.selectedLeadEvent, this.duplicateLeadEvent)
        .catch(error => {
          self.$store.dispatch('alert/error', error, { root: true });
        });
      await this.$store.dispatch("leads/getLeadEvents", this.selectedLeadEvent.lead_id);
      this.$emit('onMerged');
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.arrow-right {
    font-size: 28px;
    margin-top: 50px;
}

</style>