<template>
  <div v-loading="loading">
    <div
      class="timeline timeline-one-side"
      data-timeline-content="axis"
      data-timeline-axis-style="dashed"
    >
      <div v-if="!events.length">No data.</div>
      <div v-for="event in events" :key="event.title" class="timeline-block">
        <span class="timeline-step" :class="`badge-${event.type}`">
          <i :class="event.icon"></i>
        </span>
        <div class="timeline-content">
          <div class="d-flex justify-content-between pt-1">
            <div>
              <span class="text-muted text-sm font-weight-bold">{{
                event.title
              }}</span>
            </div>
            <div class="text-right">
              <small
                class="text-muted"
              ><i class="fas fa-clock mr-1"></i>{{ event.time }}</small>
            </div>
          </div>
          <div v-for="subevent in event.events" :key="subevent.description">
            <span class="sub-timeline-step" :class="`badge-${subevent.type}`">
              <i :class="subevent.icon"></i>
            </span>
            <h6 class="text-sm mt-1 mb-0 d-inline-block">{{ subevent.description }}</h6>
            <i class="fa fa-repeat re-queue-cursor" aria-hidden="true" v-if="subevent.should_requeue && !((event_queued_id ===  subevent.tracker_id + subevent.event) && loading)" @click="requeue(subevent.tracker_id, subevent.event)"></i>
            <i class="fa fa-spinner fa-spin re-queue-cursor" aria-hidden="true" v-if="subevent.should_requeue && (event_queued_id ===  subevent.tracker_id + subevent.event) && loading" ></i>
            <i class="fa fa-hourglass-half re-queued "  aria-hidden="true" v-if="subevent.is_requeued"></i>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeadEventService from "@/services/lead-event.service";
import Timeline from '@/components/Timeline/TimeLine';
import TimelineItem from '@/components/Timeline/TimeLineItem';
import moment from 'moment-timezone';
export default {
  props:{
    leadEventId: Number,
  },
  data() {
    return {
      events: [
      ], 
      loading: true,
      event_queued_id: '',
    };
  },
  components: {
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
  },
  mounted() {
    this.getTimeline();
  },
  methods: {
    async getTimeline() {
      this.loading = true;
      let timezone = moment.tz.guess(true);
      let timeline = await LeadEventService.timeline(this.leadEventId, timezone);
      this.events = timeline.data;
      this.loading = false;
    },
    async requeue(tracker_id, type) {
      this.event_queued_id = tracker_id + type;
      this.loading = true;
      await LeadEventService.requeueEvents(this.leadEventId, tracker_id, type);
      this.getTimeline();
    },
  },
};
</script>
<style>
@media (min-width: 992px) {
  .timeline-one-side .timeline-content {
    max-width: 100%;
  }
}
.sub-timeline-step {
  font-size: 10px;
  border-radius: 17px;
  width: 17px;
  height: 17px;
  display: inline-block;
  padding: 1px 3px;
  margin-right: 10px;
  text-align: center;
}
.re-queue-cursor {
  cursor: pointer;
  margin-left: 10px;
  color: #11cdef;
}
.re-queued {
  margin-left: 10px;
  color: #11ceef6b;
}
</style>