<template>
  <card id="lead_events">
    <div slot="header" class="row align-items-center align-item-middle">
      <div class="col-3">
        <h3 class="mb-0">Events</h3>
      </div>
      <div class="col-9 text-right">
        <a class="btn btn-sm btn-primary"  @click.stop="leadEventCreate = true" :class="{ 'disabled-link': loading }" >New</a>
        <a class="btn btn-sm btn-primary"  @click="syncFromHubspot" :class="{ 'disabled-link': loading }" >Sync from Hubspot</a>
        <a class="btn btn-sm btn-primary"  @click="viewRecommendationsPortal" v-loading="recommendationPortalLoading">Portal Link</a>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <ul class="list-group list-group-flush list my--3 row" v-loading="loading">
          <li
            v-for="leadEvent in leadEvents"
            :key="leadEvent.id + 'lead-event-item'"
            class="col-lg-12 list-group-item px-3"
          >
            <lead-event-list :lead-event="leadEvent" :can-merge="showMergeButton"></lead-event-list>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog
      title="Create Deal"
      width="800px"
      :visible.sync="leadEventCreate"
      v-if="leadEventCreate"
    >
      <create-lead-event @onSave="onCreate" :lead-id="leadId"></create-lead-event>
    </el-dialog>
  </card>
</template>
<script>

import LeadEventList from '@/views/Widgets/LeadDetails/LeadEventList';
import recommendationMixin from "@/helpers/recommendationMixin";
import CreateLeadEvent from '@/views/Widgets/LeadDetails/CreateLeadEvent';
import LeadEventService from '@/services/lead-event.service';
import { Notification } from 'element-ui';
export default {
  mixins: [recommendationMixin],
  components: {
    LeadEventList,
    CreateLeadEvent
  },
  props: {
    leadId: {
      required: true,
    },
  },
  data() {
    return {
      recommendationPortalLoading: false,
      loading: true,
      leadEventCreate: false
    };
  },
  async created() {
    await this.$store.dispatch("leads/getLeadEvents", this.leadId);
    this.loading = false;
  },
  computed: {
    leadEvents() {
      return this.$store.getters["leads/getLeadEventsByLeadId"](this.leadId);
    },
    showMergeButton() {
      if (! this.leadEvents.length) {
        return false;
      }
      return !! (this.leadEvents.length - 1);
    },
  },
  methods: {
    async syncFromHubspot() {
      this.loading = true;
      const response = await LeadEventService.syncFromHubspot(this.leadId);
      if (response.data?.success) {
        Notification.success({
          title: 'Started syncing.',
          message: 'The process of retrieving deals data from HubSpot has begun. Please check the event list in a few minutes.',
          showClose: false,
        });
      } else {
        Notification.error({
          title: 'An unknown error occured.',
          message: 'An unknown error occurred while retrieving deals from HubSpot. Please retry after some time.',
          showClose: false,
        });
      }
      
      this.loading = false;
    },
    async onCreate() {
      this.leadEventCreate = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.list li:hover {
    background-color: #f6f9fc;
    cursor: pointer;
}
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}
</style>