<template>
    <div v-loading="loading">
      <div v-if="uploadPending || !quoteUploaded" >
        <label :for="`fileUpload-${venue.id}`" class="btn btn-primary btn-sm hover-button">Upload PDF</label>
        <input 
          :id="`fileUpload-${venue.id}`"
          type="file"
          accept="application/pdf"
          @change="handleFileUpload($event)"
          class="btn btn-primary btn-sm"
          style="display:none"
        />
      </div>
      <div v-else>
        <label class="quote-date">
          <i class="fa-duotone fa-solid fa-file-pdf" @click="downloadQuote"></i> 
          {{ convertToUserDate(quoteLastUploaded) }} <i v-if="quoteDownloaded" class="text-success el-icon-circle-check"></i>
        </label>
        <button class="btn btn-sm btn-danger" @click="removeQuote">Remove</button>
      </div>
    </div>
</template>
<script>
import LeadEventService from "@/services/lead-event.service";
import moment from 'moment';
import {
  MessageBox,
  Loading
} from "element-ui";
export default {
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    venue: {
      type: Object,
      required: true,
    },
    uploadPending: {
      type: Boolean,
      required: false,
    },
    quoteUploaded: {
      type: Boolean,
      required: false,
    },
    quoteLastUploaded: {
      type: String,
      required: false
    },
    quoteDownloaded: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async handleFileUpload(event) {
      let file = event.target.files[0];
      if (! file) {
        return;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append('quote_file', file);
      formData.append('venue_id', this.venue.id);
      await LeadEventService.uploadQuote(this.leadEvent.id, formData);
      this.loading = false;
      this.$emit('update');
    },

    async downloadQuote() {
      this.loading = true;
      let data = await LeadEventService.getQuoteDownloadUrl(this.leadEvent.id, this.venue.id);
      this.loading = false;

      if (data?.data?.downloadUrl) {
        let downloadUrl = data?.data?.downloadUrl;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    async removeQuote() {
      MessageBox.confirm(
        "Are you sure to delete this quote?",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
        }
      )
        .then(({ value }) => {
            this.requestToDeleteQuote();
        })
        .catch((err) => {
          console.log(err);
        });
      
    },

    async requestToDeleteQuote() {
      this.loading = true;
      await LeadEventService.removeQuote(this.leadEvent.id, this.venue.id);
      this.loading = false;
      this.$emit('update');
    },

    convertToUserDate(utcDate) {
      return moment.utc(utcDate).local().format('DD/MM/YYYY');
    }
  }
}
</script>

<style>
.has-upload {
  position: relative;
}

.has-upload:hover .hover-button {
  opacity: 1;
}
</style>
<style scoped>
.hover-button {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.quote-date {
  font-weight: bold;
  font-size: 13px;
  margin-right: 20px;
}

.quote-date i {
  font-size: 20px;
  cursor: pointer;
}
</style>