<template>
  <div class="lead-event-list align-items-center"> 
    <div class="row" @click="selectEvent">
      <lead-event-item :lead-event="leadEvent"></lead-event-item>
      <div class="col-sm-2 controls">
        <a class="btn btn-sm btn-primary mb-2 " @click.stop="leadEventEdit = true">Edit</a>
        <a class="btn btn-sm btn-primary" @click.stop="leadEventMerge = true" v-if="canMerge && isTeamLead">Merge</a>
      </div>
    </div>
    <el-dialog
      title="Edit Event"
      width="800px"
      :visible.sync="leadEventEdit"
      v-if="leadEventEdit"
    >
      <edit-lead-event :lead-event="leadEvent" @onSave="onUpdate"></edit-lead-event>
    </el-dialog>
    <el-dialog
      title="Merge Event"
      width="800px"
      :visible.sync="leadEventMerge"
      v-if="leadEventMerge && isTeamLead"
    >
      <lead-event-merge :selected-lead-event="leadEvent" :lead-id="leadEvent.lead_id" @onMerged="onUpdate"></lead-event-merge>
    </el-dialog>
    <el-drawer
      :visible.sync="showWidgets"
      direction="rtl"
    >
      <lead-event-details :lead-event="leadEvent"></lead-event-details>
    </el-drawer>
  </div>
</template>
<script>
import EditLeadEvent from '@/views/Widgets/LeadDetails/EditLeadEvent';
import LeadEventMerge from '@/views/Widgets/LeadDetails/LeadEventMerge';
import { Tag, Drawer } from "element-ui";
import LeadEventDetails from '@/views/Widgets/LeadEventDetails/LeadEventDetails';
import LeadEventItem from '@/views/Widgets/LeadDetails/LeadEventItem.vue';
export default {
  components: {
    EditLeadEvent,
    LeadEventMerge,
    [Tag.name]: Tag,
    [Drawer.name]: Drawer,
    LeadEventDetails,
    LeadEventItem,
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    canMerge: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      leadEventEdit: false,
      leadEventMerge: false,
      showWidgets: false,
    };
  },
  computed: {
    isTeamLead() {
      return this.$store.getters["auth/isTeamlead"];
    },
  },
  methods: {
    async onUpdate() {
      this.leadEventEdit = false;
      this.leadEventMerge = false;
    },
    selectEvent() {
      if (this.leadEvent.is_data_needs_update) {
        this.leadEventEdit = true;
      } else {
        this.showWidgets = true;
      }
    },
  },
};
</script>
<style scoped>
.controls a {
    width: 60px;
}
.lead-event-list .controls {
  visibility: hidden;
}
.lead-event-list:hover .controls {
  visibility: visible;
}
</style>