<template>
    <div class="px-lg-5 py-lg-3 border-0 bg-white" style="height:420px; overflow-y: auto;">
        <div class="modal-header">
            <h5 class="modal-title text-center w-100">Manage Phone Numbers</h5>
        </div>
        <div class="modal-body py-0" v-loading="loading">
            <div class="row">
                <div class="col-lg-12">
                    <el-table
                        :data="phoneNumbers"
                        row-key="id"
                        v-loading="loading"
                        header-row-class-name="thead-light"
                        id="leads_table"
                        :default-sort="{ prop: 'phone_number', order: 'descending' }"
                        >
                        <el-table-column
                            label="Phone Number"
                            prop="phone_number"
                            min-width="100px"
                            sortable
                        >
                        </el-table-column>
                        <el-table-column
                            label="Verified"
                            prop="is_verified"
                            sortable
                        >
                        <template v-slot="{ row }">
                            <badge
                                v-if="row.is_verified"
                                rounded 
                                type="success">
                                Verified
                            </badge>
                        </template>
                        </el-table-column>
                        <el-table-column
                            label="Actions"
                            prop="is_primary"
                            sortable
                        >
                            <template v-slot="{ row }">
                                <badge
                                    v-if="row.is_primary"
                                    rounded 
                                    type="primary">
                                    Primary
                                </badge>
                                <badge
                                    v-else
                                    rounded 
                                    type="secondary">
                                    Secondary
                                </badge>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="Priority"
                            prop="is_primary"
                            sortable
                        >
                            <template v-slot="{ row }">
                                <button
                                    v-if="! row.is_primary"
                                    class=" btn btn-primary btn-sm"
                                    @click.prevent.stop="setPhoneAsPrimary(row.id)"
                                    >
                                    Set as Primary
                                </button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Table, TableColumn} from "element-ui";
    import { LeadService } from "@/services";
	export default {
		components: {
			[Table.name]: Table,
            [TableColumn.name]: TableColumn
		},
		props: {
            lead: {
                type: Object,
                required: true,
            }
		},
		data() {
			return {
				loading: false,
			};
		},
		async created() {
            this.loading = true;
            await this.$store.dispatch(
                "leads/getPhoneNumbers",
                this.lead.id
            );
            this.loading = false
		},
		computed: {
            phoneNumbers() {
                return this.$store.getters["leads/getPhoneNumbersByLeadId"](this.lead.id)?.phone_numbers;
            },
		},
        methods: {
            async setPhoneAsPrimary(leadPhoneNumberId) {
                this.loading = true;
                await LeadService.setPhoneAsPrimary(this.lead.id, {leadPhoneNumberId});

                await this.$store.dispatch(
                    "leads/getPhoneNumbers",
                    this.lead.id
                );

                await this.$store.dispatch("leads/getSingle", { id: this.lead.id });

                this.loading = false;
            }
        }
	};
</script>

<style scoped>
.on-hover {
    display: none;
}
tr:hover .on-hover {
    display: block;
}
</style>