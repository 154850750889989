<template>
  <div class="container-fluid  align-items-center" v-loading="loading">
    <div class="row mb-2" v-if="validationErrors">
      <div class="col">
        <el-alert type="error" >
          {{ validationErrors }}
        </el-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <base-input
          type="text"
          label="Name"
          placeholder="Name"
          v-model="form.name"
        >
        </base-input>
      </div>
      <div class="col-lg-6">
        <base-input
          label="Event Type"
        >
          <el-select v-model="form.event_type" placeholder="Event Type"ß>
            <el-option value="">
            </el-option>
            <el-option
              v-for="(label, key) in eventTypes"
              :key="key"
              :label="label"
              :value="key">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label class="form-control-label">Event Date</label>
          <date-picker
            name="date"
            v-model="form.event_start"
            :config="dateOptions"
          ></date-picker>
        </div>
      </div>
      <div class="col-lg-6">
        <base-input
          type="text"
          label="Estimated Guests"
          placeholder="Estimated Guests"
          v-model="form.estimated_guests"
        >
        </base-input>
      </div>
      <div class="col-lg-6">
        <base-input
          type="text"
          label="Estimated Budget"
          placeholder="Estimated Budget"
          v-model="form.estimated_budget"
        >
        </base-input>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-6">
        <base-input
          label="Pipeline"
        >
          <el-select v-model="form.hubspot_deal_pipeline_id" disabled placeholder="Pipeline">
            <el-option
              v-for="item in pipelines"
              :key="'pipeline_' + item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-lg-6">
        <base-input
          label="Pipeline Stage"
        >
          <el-select v-model="form.hubspot_deal_stage_id" placeholder="Pipeline Stage">
            <el-option v-if="! selectedDealPipeline" value="" label=""></el-option>
            <el-option
              v-for="item in selectedDealPipeline?.stages"
              v-if="selectedDealPipeline"
              :key="'pipeline_stage_' + item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 text-right">
        <a class="btn btn-primary" @click="saveLeadEvent">Save</a>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Select,
  Option,
  Alert
} from "element-ui";
import { mapState } from "vuex";
import datePicker from "vue-bootstrap-datetimepicker";
import {eventTypes} from '@/../app.config';
import LeadEventService from '@/services/lead-event.service';
import settingService from '@/services/settings.service';
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Alert.name]: Alert,
    datePicker,
  },
  props: {
    leadId: {
      required: true,
    },
  },
  data() {
    return {
      dateOptions: {
        format: "YYYY-MM-DD",
      },
      form: {
        name: '',
        event_type: '',
        event_start: '',
        event_end: '',
        all_day: true,
        estimated_guests: 0,
        estimated_budget: 0,
        hubspot_deal_pipeline_id: '',
        hubspot_deal_stage_id	: '',
      },
      loading: false,
      validationErrors: '',
      pipelines: null,
    };
  },
  created() {
    this.getDealPipelines();
  },
  computed: {
    ...mapState("country/", {
      country: (state) => state.country || 'AU',
    }),
    eventTypes: () => eventTypes,
    selectedDealPipeline: function () {
      if (! this.pipelines) {

        return [];
      }
      let pipeline = this.pipelines.find((item) => item.event_types.indexOf(this.form.event_type) !== -1);

      return pipeline;
    },
  },
  methods: {
    validateForm() {
      this.validationErrors = '';

      let errors = [];
      if (this.form.name == "") {
        errors.push("Name");
      }

      if (this.form.event_type == "") {
        errors.push("Event type");
      }

      if (this.form.event_start == "") {
        errors.push("Event date");
      }

      if (this.form.estimated_guests == "") {
        errors.push("Estimated guests");
      } else if (!/^\d+$/.test(this.form.estimated_guests)) {
        this.validationErrors = "Estimated guests must be a number";
        return false
      }

      if (this.form.estimated_budget == "") {
        errors.push("Estimated budget");
      }  else if (!/^\d+$/.test(this.form.estimated_budget)) {
        this.validationErrors = "Estimated budget must be a number";
        return false
      }

      if (errors.length > 0) {
        this.validationErrors = "Please fill the following required fields before submitting the form. " + (errors.join(', '));
        return false;
      }

      return true;
    },
    async saveLeadEvent() {
      if (! this.validateForm()) {
        return;
      }
      
      this.loading = true;
      let self = this;
      LeadEventService.crateLeadEvent(this.leadId, this.form)
        .then(async () => {
          await self.$store.dispatch("leads/getLeadEvents", self.leadId);
          self.$emit('onSave');
          self.loading = false;
        })
        .catch((error) => {
          self.validationErrors = error.response.data.error.message;
          self.loading = false;
        });
    },
    getDealPipelines() {
      let pipelines = settingService.getDealPipelines(this.country);
      pipelines.then(data => {
        this.pipelines = data.data;
      });
    },
  },
  watch: {
    selectedDealPipeline: function() {
      if (! this.selectedDealPipeline) {
        return;
      }
      this.form.hubspot_deal_pipeline_id = this.selectedDealPipeline.id;
      this.form.hubspot_deal_stage_id = this.selectedDealPipeline.stages[0].id;
    },
  },
};
</script>