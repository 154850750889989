<template>
  <div class="container-fluid  align-items-center" v-loading="loading">
    <div class="row mb-2" v-if="validationErrors">
      <div class="col">
        <el-alert type="error" >
          {{ validationErrors }}
        </el-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <base-input
          type="text"
          label="Name"
          placeholder="Name"
          v-model="form.name"
        >
        </base-input>
      </div>
      <div class="col-lg-6">
        <base-input
          label="Event Type"
        >
          <el-select v-model="form.event_type" placeholder="Event Type" @change="eventTypeChange">
            <el-option value="">
            </el-option>
            <el-option
              v-for="(label, key) in eventTypes"
              :key="key"
              :label="label"
              :value="key">
            </el-option>
          </el-select>
        </base-input>
        <el-alert type="warning" v-if="pipelineEventTypeMismatch" >
          The connected pipeline in Hubspot does not align with the event type. We will attach the appropriate pipeline, though please note that pipeline stage information will be lost during this process.
        </el-alert>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <!--TODO use all_day to implement event date start and event date end-->
          <label class="form-control-label">Event Date</label>
          <date-picker
            name="date"
            v-model="form.event_start"
            :config="dateOptions"
          ></date-picker>
        </div>
      </div>
      <div class="col-lg-6">
        <base-input
          type="text"
          label="Estimated Guests"
          placeholder="Estimated Guests"
          v-model="form.estimated_guests"
        >
        </base-input>
      </div>
      <div class="col-lg-6">
        <base-input
          type="text"
          label="Estimated Budget"
          placeholder="Estimated Budget"
          v-model="form.estimated_budget"
        >
        </base-input>
      </div>
    </div>
    <div class="row" v-if="! leadEvent.is_pipeline_attached">
      <div class="col-lg-12">
        <el-alert
          title="Alert"
          type="info"
          description="This event is not currently synchronized with HubSpot. Clicking on the 'Save' button will update this event as a deal in HubSpot. Kindly ensure that the deal stage is accurately specified."
          show-icon>
        </el-alert>
      </div>
    </div>
    <div class="row mt-3"  v-if="! leadEvent.is_pipeline_attached">
      <div class="col-lg-6">
        <base-input
          label="Pipeline"
        >
          <el-select v-model="form.hubspot_deal_pipeline_id" disabled placeholder="Pipeline">
            <el-option
              v-for="item in pipelines"
              :key="'pipeline_' + item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-lg-6">
        <base-input
          label="Pipeline Stage"
        >
          <el-select v-model="form.hubspot_deal_stage_id" placeholder="Pipeline Stage">
            <el-option
              v-for="item in selectedDealPipeline.stages"
              :key="'pipeline_stage_' + item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 text-right">
        <a class="btn btn-primary" @click="saveLeadEvent">Save</a>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Select,
  Option,
  Alert
} from "element-ui";
import { mapState } from "vuex";
import datePicker from "vue-bootstrap-datetimepicker";
import {eventTypes} from '@/../app.config';
import LeadEventService from '@/services/lead-event.service';
import settingService from '@/services/settings.service';
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Alert.name]: Alert,
    datePicker,
  },
  props: {
    leadEvent:  {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateOptions: {
        format: "YYYY-MM-DD",
      },
      form: {
        name: this.leadEvent.name,
        event_type: this.leadEvent.event_type,
        event_start: this.leadEvent.event_start,
        event_end: this.leadEvent.event_end,
        all_day: this.leadEvent.all_day,
        estimated_guests: this.leadEvent.estimated_guests,
        estimated_budget: this.leadEvent.estimated_budget,
        hubspot_deal_pipeline_id: this.leadEvent.hubspot_deal_pipeline_id,
        hubspot_deal_stage_id	: this.leadEvent.hubspot_deal_stage_id,
      },
      loading: false,
      pipelineEventTypeMismatch: false,
      validationErrors: '',
      pipelines: null,
    };
  },
  created() {
    this.getDealPipelines();
  },
  computed: {
    ...mapState("country/", {
      country: (state) => state.country || 'AU',
    }),
    eventTypes: () => eventTypes,
    selectedDealPipeline: function () {
      if (! this.pipelines) {

        return [];
      }
      let pipeline = this.pipelines.find((item) => item.event_types.indexOf(this.form.event_type) !== -1);

      return pipeline;
    },
  },
  methods: {
    async saveLeadEvent() {
      this.loading = true;
      this.validationErrors = '';
      let self = this;
      LeadEventService.updateLeadEventData(this.leadEvent.id, this.form)
        .then(async () => {
          await self.$store.dispatch("leads/getLeadEvents", self.leadEvent.lead_id);
          self.$emit('onSave');
          self.loading = false;
        })
        .catch((error) => {
          self.validationErrors = error.response.data.error.message;
          self.loading = false;
        });
    },
    eventTypeChange(value) {
      if (this.leadEvent.attached_pipeline_types && this.leadEvent.attached_pipeline_types.indexOf(value) === -1) {
        this.pipelineEventTypeMismatch = true;
      } else {
        this.pipelineEventTypeMismatch = false;
      }
    },
    getDealPipelines() {
      let pipelines = settingService.getDealPipelines(this.country);
      pipelines.then(data => {
        this.pipelines = data.data;
      });
    },
  },
  watch: {
    selectedDealPipeline: function() {
      this.form.hubspot_deal_pipeline_id = this.selectedDealPipeline.id;
      this.form.hubspot_deal_stage_id = this.selectedDealPipeline.stages[0].id;
    },
  },
};
</script>