<template>
  <collapse :multiple-active="false">
    <collapse-item
      :name="enquiry.id"
      v-for="enquiry in enquiries"
      :key="enquiry.id"
    >
      <h3 slot="title" class="mb-0">
        {{ (enquiry.venue && enquiry.venue.name) || 'Booking form' }}
      </h3>

      <div class="row mt-3">
        <div class="col-lg-6">
          <div class="form-group">
            <label class="form-control-label">Event Date</label>
            <date-picker
              name="date"
              disabled
              v-model="enquiry.event_date"
              :config="dateOptions"
            ></date-picker>
          </div>
        </div>
        <div class="col-lg-3">
          <base-input
            disabled
            type="text"
            label="Estimated Budget"
            placeholder="Estimated Budget"
            v-model="enquiry.estimated_budget"
            input-group-classes="input-group-merge"
          >
            <template slot="prepend">$</template>
          </base-input>
        </div>
        <div class="col-lg-3">
          <base-input
            disabled
            type="text"
            label="Estimated Guests"
            placeholder="Estimated Guests"
            v-model="enquiry.estimated_guests"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <base-input
            disabled
            type="text"
            label="Event Type"
            placeholder="Event Type"
            v-model="enquiry.event_type"
          >
          </base-input>
        </div>
        <div class="col-lg-6">
          <base-input
            disabled
            type="text"
            label="Type Of Wedding"
            placeholder="Type Of Wedding"
            v-model="enquiry.type_of_wedding"
            v-if="enquiry.event_type && enquiry.event_type.toLowerCase() === 'wedding'"
          >
          </base-input>
          <base-input
            disabled
            type="text"
            label="Type Of Social Event"
            placeholder="Type Of Social Event"
            v-model="enquiry.type_of_social_event"
            v-if="enquiry.event_type && enquiry.event_type.toLowerCase() === 'social'"
          >
          </base-input>
          <base-input
            disabled
            type="text"
            label="Type Of Corporate Event"
            placeholder="Type Of Corporate Event"
            v-model="enquiry.type_of_corporate_event"
            v-if="enquiry.event_type && enquiry.event_type.toLowerCase() === 'corporate'"
          >
          </base-input>
        </div>
      </div>
      <div
        class="row"
        v-if="enquiry.event_type && enquiry.event_type.toLowerCase() === 'wedding'"
      >
        <div class="col-lg-6">
          <base-input
            disabled
            type="text"
            label="Partner's First Name"
            placeholder="Partners First Name"
            v-model="enquiry.partner_first_name"
          >
          </base-input>
        </div>
        <div class="col-lg-6">
          <base-input
            disabled
            type="text"
            label="Partner's Last Name"
            placeholder="Partners Last Name"
            v-model="enquiry.partner_last_name"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">
              Event Details
            </label>
            <textarea
              disabled
              rows="10"
              class="form-control"
              v-model="enquiry.event_details"
              placeholder="A few words about your event ..."
            >
              A few words about your event...
            </textarea>
          </div>
        </div>
      </div>
    </collapse-item>
  </collapse>
</template>

<script>
import {
  Collapse,
  CollapseItem
} from "element-ui";
import datePicker from "vue-bootstrap-datetimepicker";
import EnquiryService from '@/services/enquiry.service';

export default {
  components: {
    Collapse,
    CollapseItem,
    datePicker,
  },
  props: {
    leadId: {
      required: true,
    },
  },
  data() {
    return {
      dateOptions: {
        format: "YYYY-MM-DD",
      },
      loading: true,
      enquiries: [],
    };
  },
  async created() {
    let data = await EnquiryService.getEnquiriesByLeadId(this.leadId);
    this.enquiries = data.data;
    this.loading = false;
  },
};
</script>
