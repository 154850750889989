<template>
  <div>
    <base-dropdown
      class="karen-status-container"
      title-classes="btn btn-secondary btn-sm"
      id="karen_status_dropdown"
      title="Booking"
      :menuOnRight="true"
    >
      <a
        class="dropdown-item"
        @click="venueStatusChanged('lost')"
      >Lost</a>
      <a
        class="dropdown-item"
        @click="venueStatusChanged('won')"
      >Won</a>
      <a
        v-if="isCancellable"
        class="dropdown-item"
        @click="venueStatusChangedToCancelled()"
      >Cancelled</a>
    </base-dropdown>
    <el-dialog
      title="Mark this venue as lost?"
      width="400px"
      v-loading="loading"
      :append-to-body="true"
      :visible.sync="venueStatusChangeLost"
      v-if="venueStatusChangeLost"
    >
      <div class="row">
        <div class="col-lg-12">
          <lost-reason :venue_id="statusChangeVenueId" :lead-event="leadEvent" @submit="submitStatusUpdateRequest" @cancel="venueStatusChangeLost=false"/>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Mark this venue as won?"
      width="400px"
      :append-to-body="true"
      :visible.sync="venueStatusChangeWon"
      v-if="venueStatusChangeWon"
    >
      <div class="row">
        <div class="col-lg-12">
          <status-win-form 
            :venue_id="statusChangeVenueId" 
            :lead-event="leadEvent" 
            :event_date ="event_date"
            @submit="submitStatusUpdateRequest" 
            @cancel="venueStatusChangeWon=false"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { MessageBox } from "element-ui";
import VenueService from "@/services/venue.service";
import LostReason from '@/views/Widgets/LostWon/LostReason.vue';
import StatusWinForm from '@/views/Widgets/LostWon/StatusWinForm.vue';
export default {
  name: "lost-won-input",
  components: {
    [LostReason.name]: LostReason,
    [StatusWinForm.name]: StatusWinForm,
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    venueId: {
      required: true,
    },
    isCancellable: {
      default: false,
    },
  },
  data() {
    return {
      venueStatusChangeLost: false,
      venueStatusChangeWon: false,
      statusChangeVenueId: '',
      loading: false,
      event_date : "",
    };
  },
  computed: {
    lead() {
      return this.$store.getters["leads/getLeadById"](this.leadId);
    },
  },
  methods: {
    venueStatusChanged(value) {
      this.statusChangeVenueId = this.venueId;
      if (value === "won") {
        this.event_date = this.leadEvent.event_start;
        this.venueStatusChangeWon = true;
      } else {
        this.venueStatusChangeLost = true;
      }
    },
    venueStatusChangedToCancelled() {
      MessageBox.confirm('Are you sure to cancel this sale?', 'Cancel', {
        distinguishCancelAndClose: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async () => {
        this.loading = true;
        await VenueService.changeLeadSaleStatus(
          this.venueId,
          this.leadEvent.id,
          {
            status: "cancelled",
            lead_id: this.leadId,
          }
        ).catch((error) => {
          this.$store.dispatch('alert/error', error, { root: true });
        });
        await this.submitStatusUpdateRequest();
        this.loading = false;
      });
    },
    async submitStatusUpdateRequest() {
      this.$emit('statusChange');
      this.venueStatusChangeLost = false;
      this.venueStatusChangeWon = false;
    },
  },
};
</script>
