<template>
  <el-card shadow="never">
    <h4 class="text-center">
      {{ leadEvent.name }}
    </h4>
    <div class="mt-3 sub-details">
      <div class="d-flex">
        <i class="ni ni-calendar-grid-58 mr-2"></i>
        {{ leadEvent.event_start | moment("DD MMM YYYY")}}
      </div>
      <div class="d-flex">
        <i class="fa-light fa-users mr-2"></i>
        {{ leadEvent.estimated_guests}} PAX
      </div>
      <div class="d-flex">
        <i class="fa-light fa-circle-dollar mr-2"></i>
        {{ formattedEstimatedBudget }}
      </div>
    </div>
  </el-card>
</template>
<script>
import { mapState } from "vuex";
import { formatCurrency } from '@/util/formatCurrency';
import {
  Card
} from "element-ui";
export default {
  components: {
    [Card.name]: Card,
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("country/", {
      country: (state) => state.country,
    }),
    formattedEstimatedBudget: function() {
      return this.leadEvent.estimated_budget ? formatCurrency(this.country, this.leadEvent.estimated_budget) : this.leadEvent.estimated_budget;
    },
  },
};
</script>
<style scoped>
.sub-details div {
    margin-top: 3px;
    color: #777575;
}
</style>