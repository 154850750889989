<template>
  <div v-loading="loading" style="position: absolute;">
    <div class="mark-unavailable action-button" v-if="! isVenueUnavailable" @click="markAvailability(false)">Mark Unavailable</div>
    <div class="mark-available action-button" v-if="isVenueUnavailable" @click="markAvailability(true)">Mark Available</div>
    <div class="unavailable" v-if="isVenueUnavailable">Unavailable</div>
  </div>
</template>
<script>
import leadEventService from '@/services/lead-event.service';
export default {
  name: "venue-availability",
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    venue: {
      type: Object,
      required: true,
    },
    isVenueUnavailable: {
      required: false
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async markAvailability(availability) {
      this.loading = true;
      await leadEventService.setVenueAvailability(this.leadEvent.id, this.venue.id, availability);
      this.$emit('update');
      this.loading = false;
    }
  },
};
</script>
<style>
.has-unavailability-buttons:has(.unavailable) .avatar img,
.has-unavailability-buttons:has(.unavailable) .details {
    opacity: .3;
}
.has-unavailability-buttons:has(.unavailable) h5{
    text-decoration: line-through;
}
.has-unavailability-buttons:hover .action-button {
    opacity: 1;
}
</style>
<style scoped>
.unavailable {
    opacity: 1 !important;
    background: rgb(105, 105, 105);
}
.action-button,
.unavailable {
    position: absolute;
    font-size: 7px;
    left: 50%;
    top: 50%;
    white-space: nowrap;
    z-index: 100;
    border-radius: 2px;
    padding: 2px;
    border: 2px solid #fff;
    color: #fff;
    width: 65px;
    height: 20px;
    margin-left: -32px;
    opacity: 0;
    margin-top: -10px;
    text-align: center;
}

.action-button:hover {
    opacity: 0.9;
}

.mark-unavailable {
    background: rgb(253, 0, 0);
    z-index: 101;
}

.mark-available {
    background: rgb(0, 253, 0);
    z-index: 101;
}
</style>