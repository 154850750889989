<template>
  <div v-loading="loading">
    <base-dropdown
      class="karen-status-container"
      title-classes="btn btn-secondary btn-sm inspection-status"
      id="karen_status_dropdown"
      :title="inspectionStatus"
      :menuOnRight="true"
    >
      <a
        v-if="inspectionStatus == InspectionService.INSPECTION_STATUS_CONFIRMED"
        class="dropdown-item"
        @click="inspectionStatusChanged(InspectionService.INSPECTION_STATUS_ATTENDED)"
      >Attended</a>
      <a
        v-if="inspectionStatus == InspectionService.INSPECTION_STATUS_CONFIRMED"
        class="dropdown-item"
        @click="inspectionStatusChanged(InspectionService.INSPECTION_STATUS_NOT_ATTENDED)"
      >Didn't Attend</a>
      <a
        v-if="inspectionStatus != InspectionService.INSPECTION_STATUS_CONFIRMED"
        class="dropdown-item"
        @click="inspectionStatusChanged(InspectionService.INSPECTION_STATUS_CONFIRMED)"
      >Confirmed</a>
      <a
        class="dropdown-item cancelled"
        @click="inspectionStatusChanged(InspectionService.INSPECTION_STATUS_CANCELLED)"
      >Cancelled</a>
    </base-dropdown>
  </div>
</template>
<script>
import { MessageBox } from "element-ui";
import InspectionService from "@/services/inspection.service";
export default {
  name: "inspection-status-dropdown",
  props: {
    inspectionId: {
      required: true,
    },
    inspectionStatus: {
      required: true,
    },
  },
  data() {
    return {
      InspectionService,
      loading: false,
    };
  },
  methods: {
    async inspectionStatusChanged(status) {
      if (status === InspectionService.INSPECTION_STATUS_CANCELLED) {
        return this.cancelInspection(this.inspectionId);
      }
      this.loading = true;
      await InspectionService.changeInspectionStatus(
        this.inspectionId,
        {
          status,
        }
      ).catch((error) => {
        this.$store.dispatch('alert/error', error, { root: true });
      });
      await this.triggerEventForStatusChange();
      this.loading = false;
    },
    cancelInspection(inspectionId) {
      MessageBox.confirm('Are you sure you want to cancel this inspection request?', '', {
        distinguishCancelAndClose: true,
        confirmButtonText: 'Yes, Notify the lead and venue',
        cancelButtonText: 'Cancel',
      }).then(async () => {
        this.loading = true;
        await this.$store.dispatch("inspections/cancelInspection", inspectionId);
        this.loading = false;
        await this.triggerEventForStatusChange();
      });
    },
    async triggerEventForStatusChange() {
      this.$emit('statusChange');
    },
  },
};
</script>
<style>
.inspection-status {
  text-transform: capitalize;
}
</style>