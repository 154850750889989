<template>
  <div class="mt-2">
    <h5 class="mb-0">
      <i class="fas fa-map-marker-alt"></i> {{ venue_name }}
    </h5>
    <badge
      v-if="inspection_date && inspection_time"
      rounded
      :type="inspectionStatusBadgeType(inspection_status)"
    >
      <i class="fas fa-calendar-alt"></i>
      {{ inspection_date | moment("dddd, MMMM Do YYYY") }}
      <span v-if="inspection_status == InspectionService.INSPECTION_STATUS_CONFIRMED">@ {{ inspection_time }}</span>
      <span v-else-if="inspection_status == InspectionService.INSPECTION_STATUS_REQUESTED">
        <span>@ {{ inspection_time }} -</span>
        {{ inspection_status | removeUnderscore}}
      </span>
      <span v-else>
        <span>-</span>
        {{ inspection_status | removeUnderscore}} 
      </span>
    </badge>
    <badge
      v-if="! (inspection_date && inspection_time) && sale_status"
      rounded
      type="info"
    >No Inspection - Custom Booking</badge>
    <badge v-if="sale_status" rounded :type="saleStatusBadgeType(sale_status)" class="ml-2">
      <span>
        {{ sale_status }} 
      </span>
      <span v-if="isSaleWon(sale_status)">
        : #{{sale_id}}
      </span>
    </badge>
    <div class="float-right position-absolute controls">
      <button
        class="btn btn-sm btn-primary edit"
        @click.stop="editInspectionDate(inspection_id)"
        v-if="isEditable()"
      >
        Edit
      </button>
      <lost-won-input 
        class="inspection-item-controls"
        :leadEvent="leadEvent" 
        :venueId="venue_id"
        :isCancellable="is_sale_cancellable"
        @statusChange="statusChange"
      >
      </lost-won-input>
      <inspection-status-dropdown
        class="inspection-item-controls inspection-status"
        v-if="showStatusControls()"
        :inspectionId="inspection_id"
        :inspectionStatus="inspection_status"
        @statusChange="statusChange"
      >
      </inspection-status-dropdown>
    </div>
  </div>
</template>
<script>
import InspectionService from "@/services/inspection.service";
import SaleService from '@/services/saleService';
import InspectionStatusDropDown from '@/views/Widgets/Inspections/InspectionStatusDropDown.vue';
import LostWonInput from '@/views/Widgets/LostWon/LostWonInput.vue';
export default {
  name: "venue-inspection-lists",
  components: {
    [LostWonInput.name]: LostWonInput,
    [InspectionStatusDropDown.name]: InspectionStatusDropDown,
  },
  data() {
    return {
      InspectionService,
    };
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    venue_name: {
      type: String,
      required: true,
    },
    inspection_date: {
      type: String,
      required: false,
    },
    inspection_time: {
      type: String,
      required: false,
    },
    inspection_status: {
      type: String,
      required: false,
    },
    sale_status: {
      type: String,
      required: false,
    },
    sale_id: {
      type: Number,
      required: false,
    },
    inspection_id: {
      type: Number,
      required: false,
    },
    venue_id: {
      type: Number,
      required: true,
    },
    is_sale_cancellable: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    saleStatusBadgeType() {
      return function (sale_status) {
        let badge = 'secondary';
        switch (sale_status && sale_status.toLowerCase()) {
        case "lost":
        case "cancelled":
          badge = 'warning';
          break;
        case "won": 
          badge = "success";
          break;
        default:
          break;
        }
        return badge;
      };
    },
    inspectionStatusBadgeType() {
      return function (inspection_status) {
        let badge = 'info';
        switch (inspection_status && inspection_status.toLowerCase()) {
        case InspectionService.INSPECTION_STATUS_REQUESTED:
          badge = 'yellow';
          break;
        case InspectionService.INSPECTION_STATUS_RESCHEDULED:
          badge = 'pink';
          break;
        case InspectionService.INSPECTION_STATUS_CONFIRMED:
          badge = 'info';
          break;
        case InspectionService.INSPECTION_STATUS_ATTENDED: 
          badge = "success";
          break;
        case InspectionService.INSPECTION_STATUS_NOT_ATTENDED: 
          badge = "warning";
          break;
        case InspectionService.INSPECTION_STATUS_CANCELLED: 
          badge = "danger";
          break;
        default:
          break;
        }
        return badge;
      };
    },
    isSaleWon() {
      return function (sale_status) {
        if(sale_status.toLowerCase() === SaleService.SALE_STATUS_WON) {
          return 1;
        }
        return 0;
      };
    },
  },
  methods: {
    isEditable() {
      if (
        (
          this.inspection_status === InspectionService.INSPECTION_STATUS_REQUESTED ||
          this.inspection_status === InspectionService.INSPECTION_STATUS_CONFIRMED 
        ) && this.inspection_id
      ) {
        return true;
      }
      return false;
    },
    showStatusControls() {
      if ( 
        this.inspection_status === InspectionService.INSPECTION_STATUS_CANCELLED ||
        this.inspection_status === InspectionService.INSPECTION_STATUS_RESCHEDULED ||
        !this.inspection_status
      ) {
        return false;
      }
      return true;
    },
    editInspectionDate(inspectionId) {
      this.$emit('edit', inspectionId);
    },
    statusChange() {
      this.$emit('statusChange');
    },
  },
  filters: {
    removeUnderscore (str) {
      return str.replace(/_/g, " ");
    },
  },
};
</script>

<style lang="scss" scoped>
.inspection-item .edit,
.inspection-item .cancel  {
  float: right;
  display: none;
}
.inspection-item:hover .edit,
.inspection-item:hover .cancel{
  display: block;
}
.lead_inspection_widget .controls{
  right: 0;
  top: 30%;
}
.lead_inspection_widget .controls button {
  margin-right: 5px;;
}
.inspection-item .inspection-item-controls {
  display: none;
}
.inspection-item:hover .inspection-item-controls {
  display: inline-block;
}
.inspection-item .badge-yellow {
	color: #bd8f05;
	background-color: #fbf8b9;
}
.inspection-item .badge-pink {
	color: #AC001E;
	background-color: #FF647F8A;
}
</style>
