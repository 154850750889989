<!--basic template-->
<template>
  <div>
    <p :class="`badge  badge-pill mb-0 ` + lastUpdatedBadge">Schedule last updated: {{ lastUpdated }}.</p>
    <p :class="`badge badge-pill mb-0 ` + externalCalendarSyncBadge">External Calendar Sync {{ externalCalendarSyncStatus }}</p>
  </div>
</template>
<script>
// import moment
import moment from 'moment';
export default {
  name: 'VenueAvailabilityBadge',
  props: {
    venue: Object,
  },
  computed: {
    lastUpdated() {
      let availability = this.venue.availability;
      if (availability && availability.availbility_last_updated_date) {
        // with time ago format
        return moment(availability.availbility_last_updated_date).fromNow();
      }
      return 'not available';
    },
    lastUpdatedBadge() {
      // if availbility_last_updated_date is  0-7 days ago  badge-sucdess, 8-14 days ago badge-warning, 15+ days ago badge-danger
      let availability = this.venue.availability;
      if (availability && availability.availbility_last_updated_date) {
        let lastUpdated = moment(availability.availbility_last_updated_date);
        let now = moment();
        let diff = now.diff(lastUpdated, 'days');
        if (diff >= 0 && diff <= 7) {
          return 'badge-success';
        } else if (diff >= 8 && diff <= 14) {
          return 'badge-warning';
        } else if (diff >= 15) {
          return 'badge-danger';
        }
      }
      return 'badge-danger';
    },
    externalCalendarSyncStatus() {
      let availability = this.venue.availability;
      if (availability && availability.integrated_external_calendar) {
        // if synced return a tick mark otherwise return a cross mark
        return availability.integrated_external_calendar ? '✓' : '✗';
      }
      return '✗';
    },
    externalCalendarSyncBadge() {
      let availability = this.venue.availability;
      if (availability && availability.integrated_external_calendar) {
        return availability.integrated_external_calendar ? 'badge-success' : 'badge-danger';
      }
      return 'badge-danger';
    },
  },
};
</script>