<template>
  <div id="inspections"  class="pt-4 pb-4 ml-4 mr-4  border-top">
    <div  class="row align-items-center pb-4">
      <div class="col-6 ">
        <h3 class="mb-0">Site Visits / Sales</h3>
      </div>
      <div class="col-6 text-right">
        <a
          @click="venueCustomBooking()"
          class="btn btn-sm btn-primary mr-1"
        >
          + Booking
        </a>
        <a
          @click.prevent="gotToVenueShortList"
          class="btn btn-sm btn-primary site-visit-link"
        >
          + Site Visit
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <venue-inspections :lead-event="leadEvent"></venue-inspections>
      </div>
    </div>
    <el-dialog
      title="Mark this venue as won?"
      width="400px"
      v-loading="loading"
      :visible.sync="showVenueStatusChangeWon"
      v-if="showVenueStatusChangeWon"
      :append-to-body="true"
    >
      <div class="row">
        <div class="col-lg-12">
          <status-win-form 
            :lead-event="leadEvent" 
            :event_date ="event_date"
            @submit="submitStatusUpdateRequest" 
            @cancel="showVenueStatusChangeWon=false" 
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VenueInspections from "@/views/Widgets/Inspections/VenueInspections";
import StatusWinForm from '@/views/Widgets/LostWon/StatusWinForm.vue';
export default {
  components: {
    VenueInspections,
    [StatusWinForm.name]: StatusWinForm,
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showVenueStatusChangeWon: false,
    };
  },
  methods: {
    gotToVenueShortList() {
      this.$router.push({ name: "LeadInspections", params: { id: this.leadEvent.lead_id, eventId: this.leadEvent.id } });
    },
    venueCustomBooking() {
      this.event_date = this.leadEvent.event_start;
      this.showVenueStatusChangeWon = true;
    },
    async submitStatusUpdateRequest(data) {
      let self = this;
      await self.$store.dispatch(
        "inspections/getInspections",
        self.leadEvent.id
      );
      await this.$store.dispatch(
        "inspections/getSalesWithoutInspections",
        self.leadEvent.id
      ); 
      this.showVenueStatusChangeWon = false;
    },
  },
};
</script>