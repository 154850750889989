<template>
  <el-form
    class="karen-status-change-request"
    v-loading="loading"
    :inline="true"
    :model="formData"
    ref="karen-status-change-request"
  >
    <div>
      <base-input label="Lost Reason">
        <el-select
          v-model="formData.lost_reason"
          size="mini"
          filterable
          placeholder="Lost Reason"
        >
          <el-option
            v-for="(value, key) in lostReasons"
            :key="key"
            :label="value"
            :value="value"
          >
          </el-option>
        </el-select>
      </base-input>
      <textarea
        rows="6"
        class="form-control"
        id="details_text_area"
        v-model="formData.lost_details"
        placeholder="Details"
      ></textarea>
    </div>
    <div class="text-right">
      <base-button
        type="secondary"
        native-type="button"
        id="submit_karen_status_change_request"
        class="mt-4"
        @click="cancel()"
      >
        Cancel
      </base-button>
      <base-button
        type="primary"
        native-type="button"
        id="submit_karen_status_change_request"
        class="mt-4"
        :disabled="disableSubmitButtonIfValidationFails"
        @click="submitLost()"
      >
        Confirm
      </base-button>
    </div>
  </el-form>
</template>
<script>
import {
  FormItem,
  Form,
  TimeSelect,
  Select,
  Option
} from "element-ui";
import { VenueService, VenueAuditLog } from "@/services";
export default {
  name: "lost-reason",
  components: {
    [FormItem.name]: FormItem,
    [Form.name]: Form,
    [TimeSelect.name]: TimeSelect,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
    venue_id: {
      required: true,
    },
    lost_reason: {
      required: false,
    },
    lost_details: {
      required: false,
    },
  },
  data: function() {
    return {
      loading:true,
      formData: {
        status: "lost",
        lead_event_id: this.leadEvent.id,
        lost_reason: this.lost_reason,
        lost_details: this.lost_details,
        venue_id: this.venue_id,
      },
    };
  },
  computed: {
    lostReasons() {
      return this.$store.getters["venues/getLostStatuses"]();
    },
    disableSubmitButtonIfValidationFails() {
      if (!this.formData.lost_details || !this.formData.lost_reason) {
        return true;
      }
      return false;
    },
  },
  async created() {
    await this.$store.dispatch("venues/getVenueLostStatus");
    this.loadSaleDataIfNotGiven();
    this.loading = false;
  },
  methods: {
    submitLost() {
      VenueService.changeLeadSaleStatus(
        this.formData.venue_id,
        this.leadEvent.id,
        this.formData
      ).then(async () => {
        this.$emit('submit', this.formData);
        this.resetForm();
      }).catch((error) => {
        this.$store.dispatch("alert/error", error, { root: true });
      });
    },
    resetForm() {
      this.formData.venue_id = "";
      this.formData.lost_reason = "";
      this.formData.lost_details = "";
    },
    cancel() {
      this.$emit('cancel');
    },
    async loadSaleDataIfNotGiven() {
      if (this.lost_reason) {
        return;
      }
      this.loading = true;
      let data = await VenueAuditLog.getVenueSaleData(this.venue_id, this.leadEvent.id);
      this.loading = false;
      if (data) {
        this.formData.lost_details = data.lost_details;
        this.formData.lost_reason = data.lost_reason;
      }
    },
  },
};
</script>